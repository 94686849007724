<div class="text">
  <h4>Label</h4>

  <span class="total">1</span>

  <span>
    <mat-icon color="primary">trending_up</mat-icon>
  </span>

  <span class="description">
    3%
  </span>

  <span> of target</span>
</div>
<div class="widget"></div>

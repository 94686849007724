import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  get infinity() {
    let permissions = this.ngxPermissionService.getPermissions();
    return permissions['infinity::access'] !== undefined;
  }

  constructor(
    public ngxPermissionService: NgxPermissionsService,
  ) { }

  ngOnInit() {
  }

}

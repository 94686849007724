<mat-toolbar color="primary">
  <div class="container">
    <mat-toolbar-row>
      <div *ngIf="infinity===false">
        <a routerLink="/">
          <span class="d-none d-sm-block" style="margin-left: 10px; margin-right: 36px; pointer-events: none">
            <img src="/assets/images/investidorhorizontal.png" style="max-width: 196px;" alt="">
          </span>
          <span class="d-block d-sm-none" style="margin-left: 10px; margin-right: 36px; pointer-events: none">
            <img src="/assets/images/i50_favicon.png" style="max-width: 196px;" alt="">
          </span>
        </a>
      </div>
      <div *ngIf="infinity===true">
        <a routerLink="/">
          <span class="d-none d-sm-block" style="margin-left: 10px; margin-right: 36px; pointer-events: none">
            <img src="/assets/images/investidorhorizontal.png" style="max-width: 196px;" alt="">
          </span>
          <span class="d-block d-sm-none" style="margin-left: 10px; margin-right: 36px; pointer-events: none">
            <img src="/assets/images/i50_favicon.png" style="max-width: 196px;" alt="">
          </span>
        </a>
      </div>
      <div class="col-12 col-md-12" fxFlex fxLayout="row" fxLayoutAlign="space-between">
        <div class="d-none d-sm-block col-11 col-md-11">
          <ul class="list-menu" fxLayout="row" fxLayoutGap="20px">
            <li>
              <a target="_blank" (click)="openModal()">
                <mat-icon>navigation_outline</mat-icon>
                Comece por Aqui
              </a>
            </li>
            <li>
              <a [routerLink]="['fale-conosco']">
                <mat-icon>chat_bubble_outline</mat-icon>
                Fale Conosco
              </a>
            </li>
          </ul>
        </div>
        <div class="d-block d-sm-none" style="margin-top: 32px;" fxLayout="row" fxLayoutGap="20px">
          <div>
            <a target="_blank" (click)="openModal()">
              <mat-icon style="margin-left: 2vh; margin-right: 6vh;">navigation_outline</mat-icon>
            </a>
            <a [routerLink]="['fale-conosco']">
              <mat-icon>chat_bubble_outline</mat-icon>
            </a>
          </div>
        </div>
        <ul class="col-1 col-md-1" fxLayout="row" fxLayoutGap="20px">
          <li style="margin-top: 12px!important;">
            <button mat-icon-button style="margin-top: 22px; text-align: center; padding-left: 8px;"
              [matMenuTriggerFor]="menu">
              <mat-icon>exit_to_app</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="logout()" mat-menu-item>
                <mat-icon style="margin-top: 10px;">exit_to_app</mat-icon>
                Sair
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </div>
</mat-toolbar>
<div style="height: 5px;"
  [style.background]="'radial-gradient(circle at center, #2addb0, #0d5eba)'">
</div>

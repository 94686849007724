import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { AuthService } from '../../shared/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  baseUrl: string = environment.baseUrl;
  accessToken: string;

  get infinity() {
    let permissions = this.ngxPermissionService.getPermissions();
    return permissions['infinity::access'] !== undefined;
  }

  constructor(
    public authService: AuthService,
    public ngxPermissionService: NgxPermissionsService,
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('access_token');
  }

}

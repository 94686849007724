import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  sideBarOpen = true;
  mobileQuery: MediaQueryList;


  get infinity() {
    let permissions = this.ngxPermissionService.getPermissions();
    return permissions['infinity::access'] !== undefined;
  }

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public ngxPermissionService: NgxPermissionsService,) {
    this.mobileQuery = media.matchMedia('(max-width: 1120px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (window.screen.width < 1121) {
      this.sideBarOpen = false;
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() { }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
}

import { environment } from './../environments/environment';
import { OidcConfigService } from 'angular-auth-oidc-client';

export const authConfig = {
    stsServer: environment.authority,
    redirectUrl: window.location.origin + '/callback',
    postLogoutRedirectUri: window.location.origin,
    clientId: environment.clientId,
    scope: 'openid profile',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
};
export function configureAuth(oidcConfigService: OidcConfigService,) {
    return () =>
        oidcConfigService.withConfig(authConfig);
}

<div class="cadastro-main">
  <div class="vertical-align">
    <img
      src="/assets/images/logo_tf_horizontal.png"
      style="max-width: 240px; margin: 20px auto; display: block"
      alt="Empreender Dinheiro"
    />
    <div class="row center">
      <div class="col-md-12 col-sm-12 vertical-align">
        <div class="cadastro-container">
          <div class="loading">
            <mat-progress-bar
              *ngIf="loading"
              mode="indeterminate"
              color="primary"
            ></mat-progress-bar>
          </div>

          <h5><b> Crie já sua conta na Empreender Dinheiro</b>.</h5>
          <p>Por favor, preencha as informações solicitadas.</p>

          <form (submit)="submit()" [formGroup]="loginForm">
            <mat-form-field appearance="outline">
              <mat-label for="fullName">Nome Completo</mat-label>
              <input
                type="text"
                matInput
                autofocus
                formControlName="fullName"
                [ngClass]="{
                  'is-invalid': submitted && loginForm.controls.fullName.errors
                }"
              />
              <mat-hint *ngIf="submitted && loginForm.controls.fullName.errors">
                <div *ngIf="loginForm.controls.fullName.errors.required">
                  Nome Completo é obrigatório
                </div>
                <div *ngIf="loginForm.controls.fullName.errors.pattern">
                  Não é seu nome Completo
                </div>
              </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label for="email">E-mail</mat-label>
              <input
                type="text"
                matInput
                formControlName="email"
                [ngClass]="{
                  'is-invalid': submitted && loginForm.controls.email.errors
                }"
              />
              <mat-hint *ngIf="submitted && loginForm.controls.email.errors">
                <div *ngIf="loginForm.controls.email.errors.required">
                  E-mail é obrigatório
                </div>
                <div *ngIf="loginForm.controls.email.errors.email">
                  Não é um e-mail válido.
                </div>
              </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label for="password">Senha</mat-label>
              <input
                type="password"
                matInput
                formControlName="password"
                [ngClass]="{
                  'is-invalid': submitted && loginForm.controls.password.errors
                }"
              />
              <mat-hint
                *ngIf="submitted && loginForm.controls.password.errors"
                mat-hint
              >
                <div *ngIf="loginForm.controls.password.errors.required">
                  Sua senha é obrigatório
                </div>
                <div *ngIf="loginForm.controls.password.errors.minlength">
                  Sua senha deve ter pelo menos 6 caracteres.
                </div>
              </mat-hint>
            </mat-form-field>
            <div
              *ngIf="error"
              style="
                padding: 10px 12px;
                margin-bottom: 10px;
                border: 1px #c300004a solid;
                border-radius: 5px;
                background: rgba(255, 0, 0, 0.05);
                color: #c30000;
              "
            >
              {{ error }}
            </div>
            <div style="margin-top: 10px">
              <button
                [disabled]="!loginForm.controls.valid || loading"
                type="submit"
                [disabled]="loading"
                mat-raised-button
                style="float: right"
                color="primary"
              >
                Cadastrar-me
              </button>
              <button
                type="button"
                routerLink="/login"
                [disabled]="loading"
                mat-stroked-button
                color="primary"
              >
                Voltar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

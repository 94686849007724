import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public httpClient: HttpClient) {}
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    console.log('isAuthenticated()', !!token);
    return !!token;
  }
  login(email, password) {
    return this.httpClient
      .post(environment.url + 'auth/login', {
        email,
        password,
      })
      .pipe(
        tap((data: any) => {
          console.log('pipe', data);
          localStorage.setItem('access_token', data.access_token);
        })
      );
  }
  logout() {
    localStorage.removeItem('access_token');
  }
}

<div class="container_full">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8">
                <h3>
                    Fale Conosco:
                </h3>
                <p style="color: #094035;font-size: 16px;">
                    Saiba que <b>estamos sempre torcendo por você</b> e por seu sucesso financeiro. <br>Escolha uma das
                    opções e
                    <b>entre
                        em
                        contato
                        conosco</b>:
                </p>
            </div>            
            <div class="col-12 col-md-4" style="text-align: right;">
                <button class="buttons" style="color: #fff;" mat-flat-button color="primary" routerLink="/">
                    <mat-icon matListIcon>arrow_back</mat-icon>

                    Voltar para Ferramenta
                </button>
            </div>
        </div>
        <section>
            <div class="row">
                <div class="col-md-4">
                    <a class="item" target="_blank"
                        href="https://api.whatsapp.com/send?phone=5581982611988&text=Gostaria de deixar um feedback sobre o investidor 5.0 ou ferramentas dele:">
                        <div class="image">
                            <img src="/assets/images/suporte-1.png" alt="">
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a class="item" target="_blank"
                        href="https://api.whatsapp.com/send?phone=5581982611988&text= Olá preciso de ajuda, venho do Investidor 5.0, você poderia me ajudar?">
                        <div class="image">
                            <img src="/assets/images/suporte-2.png" alt="">
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a class="item" target="_blank"
                        href="https://api.whatsapp.com/send?phone=558196838285&text= Olá, venho do Investidor 5.0 e quero falar com o comercial">
                        <div class="image">
                            <img src="/assets/images/suporte-3.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
        </section>
        <hr style="margin-top: 96px;">
        <div style="text-align: left;">
            <button class="buttons" style="color: #fff;" mat-flat-button color="primary" routerLink="/">
                <mat-icon matListIcon>arrow_back</mat-icon>

                Voltar para Ferramenta
            </button>
        </div>
    </div>
</div>
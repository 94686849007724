import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { configureAuth } from './auth.config';
import { DefaultModule } from './layouts/default/default.module';
import { FaleConoscoComponent } from './modules/fale-conosco/fale-conosco.component';
import { HomeComponent } from './modules/home/home.component';
import { AuthGuard } from './shared/auth-guard.service';
import { DividaPipe } from './shared/divida.pipe';
import { MilionMoneyPipe } from './shared/milion-money.pipe';
import { NormalMoneyPipe } from './shared/normal-money.pipe';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    AppComponent,
    CadastroComponent,
    NormalMoneyPipe,
    MilionMoneyPipe,
    DividaPipe,
    HomeComponent,
    FaleConoscoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AuthModule.forRoot(),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    AuthGuard,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-start',
  templateUrl: './modal-start.component.html',
  styleUrls: ['./modal-start.component.scss'],
})
export class ModalStartComponent {
  constructor(public dialogRef: MatDialogRef<ModalStartComponent>) { }

  onNoClick(): void {
    localStorage.firstNovo = false;
    this.dialogRef.close();
  }
}
